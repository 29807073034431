/* eslint-disable */
import React, { useEffect } from 'react';
import { getBaseUrl, ACCOUNT, SPA } from '../Network/baseUrl';

const AuthCallback = () => {

  useEffect(() => {
    const accountId = localStorage.getItem('account-id');
    if (window.clarity && accountId) {
      window.clarity("identify", accountId);
    }

    (async () => {
      try {
        const url = new URL(`${getBaseUrl(ACCOUNT)}/sso-callback`);
  
        url.search = new URLSearchParams(location.search).toString();
        const response = await fetch(url);
  
        const json = await response.json();
  
        const accessToken = json.access_token;
  
        localStorage.setItem('token', accessToken); 
  
        const redirectURL = localStorage.getItem('intended-route')
  
        window.location.replace(`${getBaseUrl(SPA)}${redirectURL}`);
      } catch (error) {
        console.warn(error)
      }


    })();
  }, []);

  return (
    <div></div>
  )
}

export default AuthCallback;
