import {
  GET_FLEX_FORM,
  GET_FLEX_FORM_SUCCESS,
  GET_FLEX_FORM_FAIL,
  REMOVE_FLEX_FORM_ERROR,
  VERIFY_FLEX_FORM,
  VERIFY_FLEX_FORM_SUCCESS,
  VERIFY_FLEX_FORM_FAIL,
  SUBMIT_FLEX_FORM,
  SUBMIT_FLEX_FORM_SUCCESS,
  SUBMIT_FLEX_FORM_FAIL,
  RESET_FILLED_DATA,
  UPDATE_FILLED_DATA
} from "../Assets/Constant/CompleteProfileForm";
import getTransformedPayload from "./utils/payloadTransformer";

const initialState = {
  loading: false,
  data: [],
  filledData: {},
  entityType: [],
  field_group: [],
  error: null,
};

const flexFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILLED_DATA:
      const transformedPayload = getTransformedPayload({filledData: state.filledData, updatedData: action.data});
      return {
        ...state,
        filledData: transformedPayload
      }
    case RESET_FILLED_DATA:
      return {
        ...state,
        filledData: {}
      }
    case GET_FLEX_FORM:
      return {
        ...state,
        loading: true,
      };
    case GET_FLEX_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        entityType: action.data.fields.find((item) => item.name === 'entity_type').options.map(((data) => data.value)),
        field_group: [...new Set(action.data.fields.map((data) => data.field_group))],
        data: action.data.fields,
      };
    case GET_FLEX_FORM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case REMOVE_FLEX_FORM_ERROR:
      state.data = state.data.map((item) => {
        if (item.name === action.data) {
          item.error = null;
          item.messages = [];
        }
        return item;
      })
      return state;
    case VERIFY_FLEX_FORM:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_FLEX_FORM_SUCCESS:
      return {
        ...state,
        data: state.data.map(obj => action.data.changes.find(o => o.name === obj.name) || obj),
        loading: false,
      };
    case VERIFY_FLEX_FORM_FAIL:
      const newData = state.data.map(obj => action.data.changes.find(o => o.name === obj.name) || obj);
      const newDataWithErrors = newData.map(obj => {
        let result = action.errors.filter(id => obj.name === id.name);
        if (result.length > 0) {
          return {
            ...obj,
            messages: result[0].messages,
            error: true
          }
        }
        return obj;
      })
      return {
        ...state,
        data: newDataWithErrors,
        loading: false,
      };
    case SUBMIT_FLEX_FORM:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_FLEX_FORM_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case SUBMIT_FLEX_FORM_FAIL:
      return {
        ...state,
        data: state.data.map(obj => {
          let result = action.errors.filter(id => obj.name === id.name);
          if (result.length > 0) {
            return {
              ...obj,
              messages: result[0].messages,
              error: true
            }
          }
          return obj;
        }),
        loading: false,
      };
    default:
      return state;
  }
};

export default flexFormReducer;
