import axios from 'axios';
import { getBaseUrl, ACCOUNT } from './baseUrl';
import queryString from 'query-string';
import {parseJwt} from "./utils/JWTUtil";
import RefreshTokenProvider from "./utils/RefreshTokenProvider";

const http = axios.create({
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});
http.defaults.validateStatus = function () {
    return true;
};


const decodeToken = (jwt) =>
    typeof jwt === "string" ? parseJwt(jwt) : null;

http.interceptors.request.use(
    async (config) => {
        let token = localStorage.getItem('token');

        const parsedString = queryString.parse(window.location.search);

        const {redirect, account_currency: accountCurrency, account_id: accountId, t, region} = parsedString;

        if (accountId){
            let decodedToken = null;
            try{
                decodedToken = decodeToken(token);
                if(accountId !== decodedToken.account_id){
                    localStorage.removeItem('token');
                    localStorage.removeItem('account-id');
                    localStorage.setItem('account-id', accountId);
                    token = null;
                    window.location.replace(`${getBaseUrl(ACCOUNT)}/login?warm=1`)
                }
            }catch (e){
                console.error('failed to decode token.')
            }
        }

        if (redirect) {
            localStorage.setItem('redirect-url', redirect);
        }

        if (region) {
            localStorage.setItem('region', region);
        }

        if (accountCurrency) {
            localStorage.setItem('account-currency', accountCurrency);
        }

        localStorage.setItem('intended-route', window.location.pathname);

        if (!token) {
            if(t) {
                localStorage.setItem('token', t);
            } else {
                window.location.replace(`${getBaseUrl(ACCOUNT)}/login?warm=1`) //TODO: Remove the param after a proper session refresh is completed
            }
        }

        if (token) config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => Promise.reject(error),
);

http.interceptors.response.use((response) => {
    if (response.status === 401) {
        RefreshTokenProvider.instance.stop();
        localStorage.removeItem('token');
        window.location.replace(`${getBaseUrl(ACCOUNT)}/login?warm=1`); //TODO: Remove the param after a proper session refresh is completed
    }

    if (response.status === 200 && response.data.is_successful) {
        const redirectUrl = localStorage.getItem('redirect-url');
        localStorage.removeItem('redirect-url');

        window.location.replace(redirectUrl);
    }

    return response;
}, (error) => {
    return Promise.reject(error);
});

export default http;
