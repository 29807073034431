import '../../index.css';
import React, {useEffect, useState} from 'react';
import { useParams } from "react-router";
import PersonalAccount from './Component/PersonalAccount';
import BusinessAccount from './Component/BusinessAccount';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { GET_EDIT_COMPLETE_PROFILE_FORM } from "../../Assets/Constant/CompleteProfileForm";

const useStyles = makeStyles({
  wrapper: {
    textAlign: 'center',
  },
  header: {
    fontFamily: "'Lato', sans-serif",
    fontSize: 28,
    backgroundColor: '#212529',
    color: 'white',
    display: 'inline-block',
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 15,
    width: '100vw'
  },
  border: {
    marginTop: 16,
    marginBottom: 16,
  },
  sectionHeader: {
    fontWeight: 'bold',
    fontFamily:  "'Lato', sans-serif",
    fontSize: 16,
    marginBottom: 16,
  },
  saveButton: {
    fontSize:20,
    textTransform: "capitalize",
    color:"white"
  },
  toggleButton: {
    backgroundColor: "white",
  },
});


const EditCompleteProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const flexForm = useSelector(state => state.flexForm)
  const [value, setValue] = React.useState('PERSONAL');

  const { id } = useParams();

  useEffect(() => {
    const accountId = localStorage.getItem('account-id');
    if (window.clarity && accountId) {
      window.clarity("identify", accountId);
    }

    if(flexForm.entityType.length === 0) {
      dispatch({
        type: GET_EDIT_COMPLETE_PROFILE_FORM,
        id,
        navigate: (entityType) => setValue(entityType)
      })
    }
  }, []);

  const renderCompleteProfile = () => {
    if(value !== '' && value === 'PERSONAL') {
      return <PersonalAccount entityType={value} update={true} type="edit" />;
    } else if(value !== '' && value === 'BUSINESS') {
      return <BusinessAccount entityType={value} update={true} type="edit" />;
    }

    return null;
  }

  return (
    <div style={{alignItems: 'center'}}>
      <div className={classes.wrapper}>
        {/*<div className={classes.header}>*/}
        {/*  <span style={{fontWeight:'bold', fontFamily: "'Lato', sans-serif"}}>Thank you for registering</span>*/}
        {/*  <br/>Almost there, we'd like to get to know you better*/}
        {/*</div>*/}
      </div>
      {renderCompleteProfile()}
    </div>

  )
};

export default EditCompleteProfile;
